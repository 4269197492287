<template>
    <div>
      <!-- search input -->
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
          </div>
        </b-form-group>
      </div>
  
      <!-- table -->
      <vue-good-table :columns="columns" :rows="rows" :rtl="direction" :search-options="{
        enabled: true,
        externalQuery: searchTerm
      }" :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }" :pagination-options="{
        enabled: true,
        perPage: pageLength
      }">
        <template slot="table-row" slot-scope="props">
  
          <!-- Column: Name -->
          <span v-if="props.column.field === 'fullName'" class="text-nowrap">
            <b-avatar :src="props.row.logo" class="mx-1" />
            <span class="text-nowrap">{{ props.row.name }}</span>
          </span>
  
          <!-- Column: Status -->
          <span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>
  
          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret>
                <template v-slot:button-content>
                  <feather-icon icon="MoreVerticalIcon" size="16" class="text-body align-middle mr-25" />
                </template>
                <b-dropdown-item @click="showProvider(props.row)">
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>Editar</span>
                </b-dropdown-item>
                <b-dropdown-item @click="showDeleteModal(props.row)">
                  <feather-icon icon="TrashIcon" class="mr-50" />
                  <span>Eliminar</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>
  
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
  
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select v-model="pageLength" :options="['3', '5', '10']" class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
      <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-modal.modal-afiliate variant="gradient-success"
        class="btn-icon rounded-circle">
        <feather-icon icon="PlusIcon" />
      </b-button>
  
      <!--Modal afiliates-->
      <b-modal id="modal-afiliate" class="position-relative" ref="modal-afiliate" cancel-variant="outline-secondary"
        ok-title="Guardar" cancel-title="Cerrar" size="lg" centered :title="afiliadoModalTitle" @ok="handleOk"
        @hidden="hideModal" :busy="overlay">
        <b-overlay :show="overlay" rounded="sm">
          <validation-observer ref="validationRules">
            <b-form @submit.stop.prevent="validateForm">
              <b-row>
                <b-col md="6">
                  <b-col cols="12">
                    <b-form-group for="name" label-cols-md="4" label="Nombre">
                      <validation-provider #default="{ errors }" ref="txtNameProvider" name="Nombre" rules="required">
                        <b-input-group class="input-group-merge" :class="isNameValid">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="UserIcon" />
                          </b-input-group-prepend>
                          <b-form-input ref="name" placeholder="Nombre(s) y Apellido(s)" v-model="form.name"
                            :state="errors.length > 0 ? false:null" @input="validateName(form.name)"
                            @blur="validateName(form.name)" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
  
                  <b-col cols="12">
                    <b-form-group label="Dirección" for="address" label-cols-md="4">
                      <validation-provider #default="{ errors }" ref="txtAddressProvider" name="Dirección"
                        rules="required">
                        <b-input-group class="input-group-merge" :class="isAddressValid.value">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MapPinIcon" />
                          </b-input-group-prepend>
                          <b-form-input id="address" placeholder="Direccion" v-model="form.address"
                            :state="errors.length > 0 ? false:null" @blur="validateField(form.address,isAddressValid)"
                            @input="validateField(form.address,isAddressValid)" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group for="rtn" label="RTN" label-cols-md="4">
                      <validation-provider #default="{ errors }" ref="txtRTNProvider" name="RTN"
                        rules="required|numberDash:isRTNValid">
                        <b-input-group class="input-group-merge" :class="isRTNValid.value">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="EditIcon" />
                          </b-input-group-prepend>
                          <b-form-input id="rtn" placeholder="000000000" v-model="form.rtn"
                            :state="errors.length > 0 ? false:null" @input="validateNumberDashField(form.rtn,isRTNValid)"
                            @blur="validateNumberDashField(form.rtn,isRTNValid)" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group for="cai" label="CAI" label-cols-md="4">
                      <validation-provider #default="{ errors }" ref="txtCAIProvider" name="CAI" rules="required">
                        <b-input-group class="input-group-merge" :class="isCAIValid.value">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="EditIcon" />
                          </b-input-group-prepend>
                          <b-form-input id="cai" placeholder="CAI" v-model="form.cai"
                            :state="errors.length > 0 ? false:null" @input="validateField(form.cai,isCAIValid)"
                            @blur="validateField(form.cai,isCAIValid)" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group for="phone" label="Teléfono" label-cols-md="4">
                      <validation-provider #default="{ errors }" ref="txtPhoneProvider" name="Teléfono"
                        rules="required|numberDash:isPhoneValid">
                        <b-input-group class="input-group-merge" :class="isPhoneValid.value">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="PhoneIcon" />
                          </b-input-group-prepend>
                          <b-form-input id="phone" placeholder="504-0000-0000" v-model="form.phone"
                            :state="errors.length > 0 ? false:null"
                            @input="validateNumberDashField(form.phone,isPhoneValid)"
                            @blur="validateNumberDashField(form.phone,isPhoneValid)" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group for="logo" label="Logotipo" label-cols-md="4">
                      <b-img id="logo" ref="logo" thumbnail fluid :src="logo" />
                      <!--require('@/assets/images/svg/placeholder.svg')-->
                    </b-form-group>
                  </b-col>
                </b-col>
  
                <b-col md="6">
                  <b-col cols="12">
                    <b-form-group for="email" label="Correo" label-cols-md="4">
                      <validation-provider #default="{ errors }" ref="txtEmailProvider" name="Correo"
                        rules="required|email">
                        <b-input-group class="input-group-merge" :class="isEmailValid.value">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="MailIcon" />
                          </b-input-group-prepend>
                          <b-form-input id="email" placeholder="nombre@dominio.com" type="email" v-model="form.email"
                            :state="errors.length > 0 ? false:null" @input="validateEmailField(form.email,isEmailValid)"
                            @blur="validateEmailField(form.email,isEmailValid)" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group for="observation" label="Observación" label-cols-md="4">
                      <validation-provider #default="{ errors }" ref="txtObservationProvider" name="Observación"
                        rules="required">
                        <b-input-group class="input-group-merge" :class="isObservationValid.value">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="EditIcon" />
                          </b-input-group-prepend>
                          <b-form-textarea id="observation" rows="2" placeholder="Observación" v-model="form.observation"
                            :state="errors.length > 0 ? false:null"
                            @input="validateField(form.observation,isObservationValid)"
                            @blur="validateField(form.observation,isObservationValid)" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group for="password" label="Contraseña" label-cols-md="4">
                      <validation-provider #default="{ errors }" ref="txtPasswordProvider" name="Contraseña"
                        rules="required" vid="Password">
                        <b-input-group class="input-group-merge" :class="isPasswordValid.value">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LockIcon" />
                          </b-input-group-prepend>
                          <b-form-input id="password" type="password" placeholder="Contraseña" v-model="form.password"
                            :state="errors.length > 0 ? false:null" @input="validateField(form.password,isPasswordValid)"
                            @blur="validateField(form.password,isPasswordValid)" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <b-form-group for="password-confirm" label="Confirmar Contraseña" label-cols-md="4">
                      <validation-provider #default="{ errors }" ref="txtConfirmPasswordProvider"
                        name="Confirmar Contraseña" rules="required|confirmed:Password">
                        <b-input-group class="input-group-merge" :class="isPasswordConfirmationValid.value">
                          <b-input-group-prepend is-text>
                            <feather-icon icon="LockIcon" />
                          </b-input-group-prepend>
                          <b-form-input id="password-confirm" type="password" placeholder="Confirmar contraseña"
                            v-model="passwordConfirm" :state="errors.length > 0 ? false:null"
                            @input="validatePConfirmationField(passwordConfirm, isPasswordConfirmationValid)"
                            @blur="validatePConfirmationField(passwordConfirm, isPasswordConfirmationValid)" />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12">
                    <label for="extension" class="mt-1">Seleccione el logotipo de la empresa</label>
                    <validation-provider #default="{ errors }" ref="logoProvider" name="Logotipo" rules="required">
                      <b-form-file id="extension" accept=".jpg, .png, .gif" @change="uploadImage"
                        :class="isLogoValid.value" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-col>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-overlay>
      </b-modal>
  
      <b-modal id="modal-danger" ref="modal-delete" ok-variant="danger" ok-title="Si" cancel-title="No"
        modal-class="modal-danger" centered title="Eliminar" @ok="deleteProvider" :busy="deleteOverlay">
        <b-overlay :show="deleteOverlay" rounded="sm">
          <b-card-text>
            Esta seguro que desea eliminar al proveedor {{this.providerName}}?
          </b-card-text>
        </b-overlay>
      </b-modal>
    </div>
  </template>
  
  <script>
  import { ValidationProvider, ValidationObserver,localize } from 'vee-validate'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import {
    BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BButton, BOverlay,
    BModal, VBModal, BForm, BRow, BCol, BFormTextarea, BCardText, BFormFile, BImg, BInputGroup, BInputGroupPrepend, BFormInvalidFeedback,
  } from 'bootstrap-vue'
  import { VueGoodTable } from 'vue-good-table'
  import store from '@/store/index'
  import { codeBasic } from '../table/vue-good-table/code'
  import CardBasicColumns from '../card/card-basic/CardBasicColumns.vue'
  import Ripple from 'vue-ripple-directive'
  import axios from 'axios'
  import logo from '@/assets/images/svg/placeholder.svg'
  import { required, email } from '@validations'
  import en from "vee-validate/dist/locale/en.json"
  import es from "vee-validate/dist/locale/es.json"
  import { extend } from 'vee-validate'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  
  localize({
    en,
    es
  });
  localize("es");
  
  extend('numberDash', {
    validate(value,args) {
      const cRegExp = /^([0-9-]+)$/
      const validValue = cRegExp.test(value)
      if (validValue) {
        return true;
      }
      args.isRTNValid = 'is-invalid'
      return 'El {_field_} contiene caracteres no validos.';
    },
    params: ['isRTNValid']
  })
  
  export default {
    components: {
      BCardCode,
      VueGoodTable,
      BAvatar,
      BBadge,
      BPagination,
      BFormGroup,
      BFormInput,
      BFormSelect,
      BDropdown,
      BDropdownItem,
      CardBasicColumns,
      BButton,
      BModal,
      BForm,
      BFormInput,
      BFormGroup,
      BRow,
      BCol,
      BFormTextarea,
      BCardText,
      BFormFile,
      BImg,
      BInputGroup,
      BInputGroupPrepend,
      ValidationProvider,
      ValidationObserver,
      BFormInvalidFeedback,
      BOverlay,
    },
    directives: {
      Ripple,
      'b-modal': VBModal,
    },
    data() {
      return {
        overlay:false,
        deleteOverlay:false,
        afiliadoModalTitle: "Nuevo Proveedor",
        providerId:null,
        isNameValid:null,
        image: null,
        isAddressValid:{
          value:null
        },
        isRTNValid:{
          value:null
        },
        isCAIValid:{
          value:null
        },
        isPhoneValid:{
          value:null
        },
        isEmailValid:{
          value:null
        },
        isObservationValid:{
          value:null
        },
        isPasswordValid:{
          value:null
        },
        isPasswordConfirmationValid:{
          value:null
        },
        isLogoValid:{
          value:null
        },
        providerInfo:"",
        providerName: "",
        logo:logo,
        config:"",
        required,
        email,
        passwordConfirm:"",
        form: {
          name: "",
          address: "",
          cai: "",
          rtn: "",
          phone: "",
          email: "",
          password: "",
          observation: "",
        },
        pageLength: 5,
        dir: false,
        columns: [
          {
            label: 'Nombre',
            field: 'fullName',
          },
          {
            label: 'Teléfono',
            field: 'phone'  
          },
          {
            label: 'Email',
            field: 'email',
          },
          {
            label: 'Observación',
            field: 'observation'
          },
         
          {
            label: 'Acciones',
            field: 'action',
          },
        ],
        rows: [],
        searchTerm: '',
        status: [{
          1: 'Current',
          2: 'Professional',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        }],
      }
    },
    computed: {
      statusVariant() {
        const statusColor = {
          /* eslint-disable key-spacing */
          Current: 'light-primary',
          Professional: 'light-success',
          Rejected: 'light-danger',
          Resigned: 'light-warning',
          Applied: 'light-info',
          /* eslint-enable key-spacing */
        }
  
        return status => statusColor[status]
      },
      direction() {
        if (store.state.appConfig.isRTL) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.dir = true
          return this.dir
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = false
        return this.dir
      },
      validationOccupation() {
        return this.occupationID.length > 1
      },
    },
    created() {
      //this.$http.get('/good-table/basic').then(res => { this.rows = res.data })
      this.getProviders()
    },
    
    methods:{
      async validateForm(){
        const txtNameValid  = await this.$refs.txtNameProvider.validate().then(value=>{return value.valid})
        const txtAddressValid = await this.$refs.txtAddressProvider.validate().then(value => { return value.valid })
        const txtRTNValid = await this.$refs.txtRTNProvider.validate().then(value => { return value.valid })
        const txtCAIValid = await this.$refs.txtCAIProvider.validate().then(value => { return value.valid })
        const txtPhoneValid = await this.$refs.txtPhoneProvider.validate().then(value => { return value.valid })
        const txtEmailValid = await this.$refs.txtEmailProvider.validate().then(value => { return value.valid })
        const txtObservationValid = await this.$refs.txtObservationProvider.validate().then(value => { return value.valid })
        const txtPasswordValid = await this.$refs.txtPasswordProvider.validate().then(value => { return value.valid })
        const txtPasswordConfirmProvider = await this.$refs.txtConfirmPasswordProvider.validate().then(value => { return value.valid })
        
        this.validateName(this.form.name)
        this.validateField(this.form.address, this.isAddressValid)
        this.validateNumberDashField(this.form.rtn, this.isRTNValid)
        this.validateField(this.form.cai, this.isCAIValid)
        this.validateNumberDashField(this.form.phone, this.isPhoneValid)
  
        this.validateEmailField(this.form.email, this.isEmailValid)
        this.validateField(this.form.observation, this.isObservationValid)
        this.validateField(this.form.password, this.isPasswordValid)
        this.validatePConfirmationField(this.passwordConfirm, this.isPasswordConfirmationValid)
        
        //console.log(txtNameValid.valid)
        //console.log(this.$refs.validationRules.fields)
        if(!this.providerId){
          const logoProvider = await this.$refs.logoProvider.validate().then(value => { return value.valid })
          
          this.validateField(this.logo, this.isLogoValid)
          if (txtNameValid && txtAddressValid && txtRTNValid && txtCAIValid && txtPhoneValid &&
            txtEmailValid && txtObservationValid && txtPasswordValid && txtPasswordConfirmProvider && 
            logoProvider){
            console.log('Save here')
            this.createProvider()
          }
        }else{
          if(this.logo){
            if(this.logo != logo){
              if (txtNameValid && txtAddressValid && txtRTNValid && txtCAIValid && txtPhoneValid &&
                txtEmailValid && txtObservationValid && txtPasswordValid && txtPasswordConfirmProvider) {
                    this.updateProvider()
              }
            }
          }
          
        }
        /*requestAnimationFrame(() => {
          this.$refs.validationRules.reset();
        });*/
      },
      handleOk(bvModalEvt) {
        // Prevent modal from closing
        bvModalEvt.preventDefault()
        // Trigger submit handler
        this.validateForm()
        //this.checkValidation(this.form.name)
      },
      async getProviders(){
        const path = "/provider/"
        await axios.get(path).then(res=>{
          this.rows = res.data
        })
      },
      async createProvider(){
        const path = `/provider/`
        this.providerInfo.append('name', this.form.name)
              this.providerInfo.append('address', this.form.address)
              this.providerInfo.append('cai', this.form.cai)
              this.providerInfo.append('rtn', this.form.rtn)
              this.providerInfo.append('phone', this.form.phone)
              this.providerInfo.append('email', this.form.email)
              this.providerInfo.append('password', this.form.password)
              this.providerInfo.append('observation', this.form.observation)
  
        this.showLoader()
        await axios.post(path,this.providerInfo).then(res=>{
          //this.makeToast('success','Nuevo afiliado',`${this.form.name} ha sido creado con exito!!!`)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Nuevo Proveedor',
              icon: 'InfoIcon',
              variant: 'success',
              text: `${this.form.name} ha sido creado con exito!!!`,
            },
          })
          this.hideModal()
          this.getProviders()
        }).catch((error)=>{
          console.log(error)
        }).finally(()=>{
          this.hideLoader()
          console.log('Completed')
        })
      },
      showProvider(provider){
        this.afiliadoModalTitle = `Editar informacion de ${provider.name}`
        this.providerId = provider.id
        this.form.name = provider.name
        this.form.address = provider.address
        this.form.cai = provider.cai
        this.form.rtn = provider.rtn
        this.form.phone = provider.phone
        this.form.email = provider.email
        this.form.password = provider.password
        this.passwordConfirm = provider.password
        this.form.observation = provider.observation
        this.logo = provider.logo
        this.showModal()
      },
      async updateProvider(){
        const path = `/provider/${this.providerId}/`
        
        if (!this.logo.includes(':image')) {
          this.showLoader()
          await axios.patch(path, {
            name: this.form.name,
            address: this.form.address,
            rtn: this.form.rtn,
            cai: this.form.cai,
            phone: this.form.phone,
            email: this.form.email,
            password: this.form.password,
            observation: this.form.observation
          }).then(res => {
            //this.makeToast('success', 'Actualizado', `${this.form.name} ha sido actualizado con exito!!!`)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Actualizado',
                icon: 'InfoIcon',
                variant: 'success',
                text: `${this.form.name} ha sido actualizado con exito!!!`,
              },
            })
            this.hideModal()
            this.getProviders()
          }).catch((error) => {
            console.log(error)
          }).finally(() => {
            console.log('Completed')
            this.hideLoader()
          })
        }else{
          this.providerInfo.append('name', this.form.name)
          this.providerInfo.append('address', this.form.address)
          this.providerInfo.append('cai', this.form.cai)
          this.providerInfo.append('rtn', this.form.rtn)
          this.providerInfo.append('phone', this.form.phone)
          this.providerInfo.append('email', this.form.email)
          this.providerInfo.append('password', this.form.password)
          this.providerInfo.append('observation', this.form.observation)
  
          this.showLoader()
          await axios.put(path, this.providerInfo).then(res => {
            //this.makeToast('success', 'Actualizado', `${this.form.name} ha sido actualizado con exito!!!`)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Actualizado',
                icon: 'InfoIcon',
                variant: 'success',
                text: `${this.form.name} ha sido actualizado con exito!!!`,
              },
            })
            this.hideModal()
            this.getProviders()
          }).catch((error) => {
            console.log(error)
          }).finally(() => {
            this.hideLoader()
            console.log('Completed')
          })
        }
      },
      showDeleteModal(provider){
        this.providerId = provider.id
        this.providerName = provider.name
        this.$refs['modal-delete'].show()
      },
      async deleteProvider(bvModalEvt){
        this.deleteOverlay = true
        bvModalEvt.preventDefault()
        const path = `/provider/${this.providerId}/`
        await axios.delete(path).then(res => {
          //this.makeToast('success', 'Eliminado', `${this.providerName} ha sido eliminado con exito!!!`)
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Eliminado',
              icon: 'Trash2Icon',
              variant: 'danger',
              text: 'El proveedor ha sido eliminado con exito!!!',
            },
          })
          this.getProviders()
        }).catch((error) => {
          console.log(error)
        }).finally(() => {
          console.log('Completed')
          this.deleteOverlay = false
          this.$refs['modal-delete'].hide()
        })
      },
      async uploadImage(e){
        const { valid } = await this.$refs.logoProvider.validate(e);
        this.isLogoValid.value = 'is-invalid'
        if(!valid){
          this.logo = logo
          return
        }
        if (valid) {
          this.isLogoValid.value = null
  
          const image = e.target.files[0];
          this.image = image
  
          this.providerInfo = new FormData()
          const reader = new FileReader()
          reader.readAsDataURL(image)
          reader.onload = e => {
            this.logo = e.target.result
            //console.log(this.logo)
          };
          
          //this.logo = '/media/images/' + image.name
          
          if(this.providerId)
            this.providerInfo.append('logo', this.image)
          else
            this.providerInfo.append('logo', e.target.files[0])
          //console.log(this.logo)
          this.config = {
            header: {
              'Content-Type': 'image/png'
            }
          }
          
        }
          },
      hideModal() {
        this.$refs['modal-afiliate'].hide()
        this.resetModal()
      },
      showModal(){
        this.$refs['modal-afiliate'].show()
      },
      makeToast(variant = null,title,message) {
        this.$bvToast.toast(message, {
          title: title,
          variant,
          solid: true,
        })
      },
      self() {
          return this
      },
      validateName(value){
        return value.length == 0 ? this.isNameValid = 'is-invalid':this.isNameValid = null
      },
      validateNumberDashField(value, validationField){
        const cRegExp = /^[0-9-]*$/
        
        const validValue = cRegExp.test(value)
        if(value.length<1){
          validationField.value = 'is-invalid'
        } else if (validValue) {
          validationField.value = null
        }else{
          validationField.value = 'is-invalid'
        }
      },
      validateEmailField(value, validationField) {
        const cRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
  
        const validValue = cRegExp.test(value)
        if (value.length < 1) {
          validationField.value = 'is-invalid'
        } else if (validValue) {
          validationField.value = null
        } else {
          validationField.value = 'is-invalid'
        }
      },
      validateField(value,validationField){
        return value.length == 0 ? validationField.value = 'is-invalid' : validationField.value = null
      },
      validatePConfirmationField(value, validationField) {
        if (value != this.form.password || value.length == 0){
          validationField.value = 'is-invalid'
        }else{
          validationField.value = null
        }
        //return value.length == 0 ? validationField.value = 'is-invalid' : validationField.value = null
      },
      resetModal(){
        this.providerInfo = null
  
        this.isNameValid = null
        this.isAddressValid.value = null
        this.isRTNValid.value = null
        this.isCAIValid.value = null
        this.isPhoneValid.value = null
        this.isEmailValid.value = null
        this.isObservationValid.value = null
        this.isPasswordValid.value = null
        this.isPasswordConfirmationValid.value = null
  
        this.providerId = null
        this.form.name = ""
        this.form.address = ""
        this.form.cai = ""
        this.form.rtn = ""
        this.form.phone = ""
        this.form.email = ""
        this.form.password = ""
        this.passwordConfirm = ""
        this.form.observation = ""
        this.logo = logo
        this.afiliadoModalTitle = "Nuevo Proveedor"
        this.hideLoader()
      },
      async showLoader(){
        this.overlay = !this.overlay
      },
      hideLoader(){
        this.overlay = false
      }
    }
  }
  </script>
  
  <style lang="scss">
    .add-afiliate-button{
      float: right;
    }
    @import '@core/scss/vue/libs/vue-good-table.scss';
  </style>